import React from 'react';
import Datepicker from 'react-tailwindcss-datepicker';

interface TimelineDatepickerProps {
  minDate: Date | null;
  maxDate: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  setMinDate: (date: Date) => void;
  setMaxDate: (date: Date) => void;
  datepickerOnChange: (
    value: { startDate: Date | string | null; endDate: Date | string | null } | null,
    minDate: Date | null,
    maxDate: Date | null,
    setMinDate: (date: Date) => void,
    setMaxDate: (date: Date) => void,
  ) => void;
}

const TimelineDatepicker = ({
  minDate,
  maxDate,
  setMinDate,
  setMaxDate,
  datepickerOnChange,
}: TimelineDatepickerProps) => {
  return (
    <Datepicker
      inputClassName={
        'date-picker-input relative transition-all duration-300 py-2.5 pl-4 pr-10 border-gray-300 dark:bg-slate-800 dark:text-white/80 dark:border-slate-600 rounded-lg font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 focus:ring-blue-500/20'
      }
      value={{
        startDate: minDate,
        endDate: maxDate,
      }}
      onChange={(value) =>
        datepickerOnChange(
          {
            startDate: value?.startDate
              ? new Date(
                  new Date(value.startDate).getTime() + new Date(value.startDate).getTimezoneOffset() * 60 * 1000,
                )
              : null,
            endDate: value?.endDate
              ? new Date(
                  new Date(
                    new Date(value.endDate).getTime() + new Date(value.endDate).getTimezoneOffset() * 60 * 1000,
                  ).setHours(23, 59, 59, 999),
                )
              : null,
          },
          minDate,
          maxDate,
          setMinDate,
          setMaxDate,
        )
      }
      maxDate={new Date()}
      startFrom={minDate}
      displayFormat={'MMM D, YYYY'}
      disabled={!minDate || !maxDate}
      showShortcuts={true}
      showFooter={true}
      configs={{
        shortcuts: {
          today: 'Today',
          yesterday: 'Yesterday',
          past: (period) => `Past ${period} Days`,
          currentMonth: 'This Month',
          pastMonth: 'Last month',
        },
        footer: {
          cancel: 'Cancel',
          apply: 'Apply',
        },
      }}
      dateLooking='backward'
      separator='-'
    />
  );
};

export default TimelineDatepicker;
