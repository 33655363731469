import React, { useEffect, useState, useRef, useCallback } from 'react';
import '../../common/Dropdown/Dropdown.scss';
import { useParams } from 'react-router-dom';
import { fetchGetDefaultTrendsDataAsync } from './../../../features/data-history/TrendLibrary/DefaultTrendsSlice.tsx';
import {
  updateGroupedChartSelection,
  updateGroupCheckedBoxes,
  updateCheckedItems,
  fetchDataHistoryTrendLibraryAsync,
} from './../../../features/data-history/TrendLibrary/TrendLibrarySlice';
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks';
import { groupedChartData } from './../../../features/data-history/TrendLibrary/ChartTypes';
import {
  updategroupChartCoordinates,
  updateOverlayCoordinates,
  updateIsTrendLibraryClicked,
  updateDateFlag,
} from '../../data-history/TrendLibrary/TrendCoordinateSlice.tsx';
import { updateSelectedView } from './../../../features/asset-analysis/TrendGroupLabel/TrendGroupLabelSlice.tsx';
import { updateCheckBoxFlag } from './../../../features/data-history/TrendLibrary/TrendLibrarySlice.tsx';
import './TrendCustomViews.scss';
import { ReactComponent as CheckIcon } from '../../../images/check.svg';
import Globe from '../../../images/globe-04.png';

interface TrendCustomViewsProps {
  views: TrendDefaultView[] | undefined | null;
  defaultOption?: string | undefined | null;
  onChange?: (view: TrendDefaultView) => void;
  onDateChange?: (startDate: string, endDate: string) => void;
  startDate: Date | null;
  endDate: Date | null;
}

export interface TrendDefaultView {
  values: {
    viewId: number;
    viewName: string;
    startDate: string;
    endDate: string;
    isSelected: boolean;
    isGlobal: boolean;
  }[];
}
interface UrlParams {
  assetId: string;
}

const TrendCustomViews: React.FC<TrendCustomViewsProps> = (props: TrendCustomViewsProps) => {
  const [isActive, setIsActive] = useState(false);

  const [selected, setIsSelected] = useState('Choose one');

  const selectedRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { assetId } = useParams<keyof UrlParams>() as UrlParams;
  const dispatch = useAppDispatch();
  const trendLibrary = useAppSelector((state) => state.trendLibrary);
  const customViewDataSlice = useAppSelector((state) => state.customViewDataSlice);
  const trendLibraryHasBeenClicked = useAppSelector((state) => state.trendItem.isTrendLibraryClicked);
  const activeKey = useAppSelector((state) => state.trendGroupLabel.activeChartKey);
  const [chartKey, setChartKey] = useState(activeKey);
  const trendCustomViews = useAppSelector((state) => state.trendGroupLabel.customViews);
  const groupName = useAppSelector((state) => state.assetGroups?.selectedGroup?.assetGroupName);
  const selectedView = useAppSelector((state) => state.trendGroupLabel.selectedView);

  useEffect(() => {
    setChartKey(activeKey);
  }, [activeKey]);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  }, []);

  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActive, handleClickOutside]);

  const handleTrendViewChange = async (view: TrendDefaultView, label: string) => {
    const clickedView = view.values.find((value) => value.viewName === label);

    if (clickedView) {
      setIsSelected(label);
      setIsActive(false);

      if (props.onChange) {
        props.onChange(view);
      }

      if (props.onDateChange) {
        props.onDateChange(clickedView.startDate, clickedView.endDate);
      }
      dispatch(fetchDataHistoryTrendLibraryAsync({ assetId: assetId, groupName: groupName }));
      if (chartKey === 0) {
        dispatch(
          fetchGetDefaultTrendsDataAsync({
            assetId,
            viewId: clickedView.viewId.toString(),
            startDate: props.startDate?.toLocaleString('en'),
            endDate: props.endDate?.toLocaleString('en'),
            isOverlay: 1,
          }),
        );
        populateDefaultTrends(customViewDataSlice.overlayDefaultCustomViewTrends, true);
      } else {
        dispatch(
          fetchGetDefaultTrendsDataAsync({
            assetId,
            viewId: clickedView.viewId.toString(),
            startDate: props.startDate?.toLocaleString('en'),
            endDate: props.endDate?.toLocaleString('en'),
          }),
        );
        populateDefaultTrends(customViewDataSlice.groupDefaultCustomViewTrends, false);
      }
      dispatch(updateCheckBoxFlag(false));
      dispatch(updateIsTrendLibraryClicked(false));
      dispatch(updateSelectedView(clickedView));
      dispatch(updateDateFlag(false));
    }
  };
  useEffect(() => {
    const view = getselectedView();
    if (view && view.viewName) {
      setIsSelected(view.viewName);
    }
  }, [selectedView, trendCustomViews]);

  const getselectedView = () => {
    if (selectedView && selectedView.viewName) {
      return selectedView;
    } else {
      if (trendCustomViews && trendCustomViews.values && trendCustomViews.values.length) {
        return trendCustomViews?.values?.find((view) => view.isSelected) ?? trendCustomViews.values[0];
      }
    }
  };

  useEffect(() => {
    if (!trendLibraryHasBeenClicked) {
      populateDefaultTrends(customViewDataSlice.overlayDefaultCustomViewTrends, true);
    }
  }, [customViewDataSlice.overlayDefaultCustomViewTrends]);

  useEffect(() => {
    if (!trendLibraryHasBeenClicked) {
      populateDefaultTrends(customViewDataSlice.groupDefaultCustomViewTrends, false);
    }
  }, [customViewDataSlice.groupDefaultCustomViewTrends]);

  const populateDefaultTrends = (defaultTrendsData, isOverlay: boolean) => {
    if (trendLibrary && trendLibrary.trends) {
      //initialized chart data based on endpoint
      if (isOverlay) {
        if (!defaultTrendsData) return;

        //update checkbox states with selections
        dispatch(updateCheckedItems(defaultTrendsData.chart1 ?? []));
        dispatch(
          updateOverlayCoordinates({
            value: customViewDataSlice.overlayDefaultCustomViewTrends,
          }),
        );
      } else {
        if (!defaultTrendsData) return;

        const chart1Data = defaultTrendsData.chart1;
        const chart2Data = defaultTrendsData.chart2;
        const chart3Data = defaultTrendsData.chart3;
        const chart4Data = defaultTrendsData.chart4;
        //update checkbox states with selections
        dispatch(
          updateGroupCheckedBoxes({
            chart1: chart1Data ?? [],
            chart2: chart2Data ?? [],
            chart3: chart3Data ?? [],
            chart4: chart4Data ?? [],
          }),
        );
        dispatch(
          updategroupChartCoordinates({
            value: customViewDataSlice.groupDefaultCustomViewTrends,
            type: 'group',
          }),
        );

        const trendsGroupSelectionClone = groupedChartData.map((item, index) => {
          const selectedTrendsArray =
            index === 0
              ? chart1Data
              : index === 1
              ? chart2Data
              : index === 2
              ? chart3Data
              : index === 3
              ? chart4Data
              : [];

          return { ...item, selectedTrendsArray };
        });
        dispatch(updateGroupedChartSelection(trendsGroupSelectionClone));
      }
    }
  };
  useEffect(() => {
    if (selectedRef.current && isActive) {
      selectedRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selected, isActive]);

  return (
    <div
      className='trend-custom-views-dropdown'
      ref={dropdownRef}
      style={{
        height: '36px',
        marginLeft: '8px',
        maxWidth: '100%',
        marginRight: '8px',
      }}
    >
      <div
        onClick={() => {
          setIsActive(!isActive);
        }}
        className='trend-custom-views-dropdown-btn'
        style={{ height: '36px' }}
      >
        <div style={{ display: 'flex', fontSize: '14px', textOverflow: 'ellipsis' }}>{selected}</div>
        <div className={isActive ? 'fas fa-caret-up' : 'fas fa-caret-down'} />
      </div>
      <div
        className='trend-custom-views-dropdown-content'
        style={{ display: isActive ? 'block' : 'none', fontSize: '14px' }}
      >
        {props.views?.map((view, index) => {
          const labels = Array.isArray(view.values) ? view.values.map((value) => value.viewName) : [];
          const currentViewsGlobalValues = Array.isArray(view.values) ? view.values.map((value) => value.isGlobal) : [];

          return (
            <React.Fragment key={index}>
              {labels.map((label, labelIndex) => (
                <div key={labelIndex}>
                  <div
                    data-testid={label}
                    ref={label === selected ? selectedRef : null}
                    onClick={() => handleTrendViewChange(view, label)}
                    className={`item ${selected === label ? 'item-selected' : ''}`}
                  >
                    <span>{label}</span>
                    {currentViewsGlobalValues && currentViewsGlobalValues[labelIndex] && (
                      <span className='globe-icon'>
                        <img src={Globe} />
                      </span>
                    )}

                    <span className='check-icon-wrap'>
                      {selected === label && <CheckIcon className='check-icon' />}
                    </span>
                  </div>
                </div>
              ))}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default TrendCustomViews;
